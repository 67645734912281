import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "./Home.css";
import SectionPricing from "../components/SectionPricing";
import MainHeroTitle from "../components/MainHero";
import SectionIntegration from "../components/SectionIntegration";
import SectionTitleYoutube from "../components/SectionTitleYoutube";
import ScrollTestimonialSection from "../components/ScrollTestimonialSection";
import SectionVerticalTimeline from "../components/SectionVerticalTimeline";
import review1 from "../images/review1.png";
import review2 from "../images/review2.png";
import review3 from "../images/review3.png";
import EmotionHoverCardSection from "../components/EmotionHoverCardSection"; // Import the Card component
import SectionTitleFreeDemo from "../components/SectionTitleFreeDemo";

const testimonials = [
  {
    img: review1,
  },
  {
    img: review2,
  },
  {
    img: review3,
  },
];

const Home = () => {
  return (
    <div>
      <div className="background-img">
        <MainHeroTitle />
        <div className="pb-[20px]">
          <div className="mb-[20px]">
            <EmotionHoverCardSection />
          </div>
        </div>
      </div>
      <div
        className="w-[50vw] blur-[200px] h-full md:h-72 absolute
            bg-gradient-to-t from-slate-900/40 to-sky-900/30 -z-20"
      ></div>
      <div
        className="ml-[50vw] w-[50vw] blur-[200px] h-full md:h-72 absolute
            bg-gradient-to-b from-indigo-900/40 to-blue-500/30 -z-20"
      ></div>
      <SectionVerticalTimeline />
      {/* <ScrollTestimonialSection testimonialImages={testimonials}/> */}
      <SectionIntegration />
      <div className="hero-content bg-transparent pt-[28px] md:pb-[60px]">
        <div className="mb-[56px]">
          <SectionTitleYoutube />
        </div>
      </div>
      <div className="hero-content bg-transparent pt-[28px] md:pb-[60px]">
        <div className="mb-[56px]">
          <SectionTitleFreeDemo />
        </div>
      </div>
      <div className="color_container">
        <SectionPricing
          title="Simple Pricing"
          subtitle="Affordable and convenient pricing tailored to your trading goals. The best value for automated trading."
        />
      </div>
    </div>
  );
};

export default Home;
