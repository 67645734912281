import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logoImage from "../images/zta_logo.webp";
import "./Navbar.css";
import { gtagTrackBookDemo } from "../util/GoogleTagUtils";

const Navbar = React.memo(() => { 
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <div className="nav_logo">
        <Link to="/">
        <img src={`${logoImage}?v=1`} alt="Logo" loading="lazy" />
        </Link>
      </div>

      {/* 🔹 Modern Burger Menu Button */}
      <div className="burger-menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
      </div>

      {/* 🔹 Mobile & Desktop Menu */}
      <ul className={`nav-links ${menuOpen ? "open" : ""}`}>
        <li className={location.pathname === "/" ? "active" : ""}>
          <Link to="/" onClick={() => setMenuOpen(false)}>
            Home
          </Link>
        </li>
        <li className={location.pathname === "/strategies" ? "active" : ""}>
          <Link to="/strategies" onClick={() => setMenuOpen(false)}>
            Strategies
          </Link>
        </li>
        <li
          className={
            location.pathname === "/portfolio_analyzer" ? "active" : ""
          }
        >
          <Link to="/portfolio_analyzer" onClick={() => setMenuOpen(false)}>
            Portfolio Analyzer
          </Link>
        </li>
        <li className={location.pathname === "/book-demo" ? "active" : ""}>
          <Link
            to="/book-demo"
            onClick={() => {
              setMenuOpen(false);
              gtagTrackBookDemo();
            }}
          >
            Book A Demo
          </Link>
        </li>
        <li className={location.pathname === "/about" ? "active" : ""}>
          <Link to="/about" onClick={() => setMenuOpen(false)}>
            About
          </Link>
        </li>
        <li className={location.pathname === "/faq" ? "active" : ""}>
          <Link to="/faq" onClick={() => setMenuOpen(false)}>
            FAQ
          </Link>
        </li>
        <li className={location.pathname === "/blog" ? "active" : ""}>
          <Link to="/blog" onClick={() => setMenuOpen(false)}>
            Blog
          </Link>
        </li>
      </ul>
    </nav>
  );
});

export default Navbar;
