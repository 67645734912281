const gtagReportConversion = (url, value) => {
  var callback = function () {
    if (typeof url !== "undefined") {
      window.location = url;
    }
  };
  window.gtag("event", "conversion", {
    send_to: "AW-11262360879/xSZVCOfIudMYEK_6p_op",
    value: value,
    currency: "USD",
    event_action: "Begin checkout",
    event_label: "Clicked Buy or Subscribe Button",
    event_callback: callback,
  });
  return false;
};

export default gtagReportConversion;

const gtagTrackBookDemo = () => {
  if (window.gtag) {
    window.gtag("event", "book_demo_click", {
      event_category: "Navigation",
      event_label: "Book A Demo",
      value: 1,
    });
  }
};

export { gtagTrackBookDemo };

const gtagTrackYearlyPricingClick = () => {
  if (window.gtag) {
    window.gtag("event", "yearly_pricing_card_clicked", {
      event_category: "Pricing",
      event_label: "Lease for $997/year",
      value: 997,
    });
  }
};

export { gtagTrackYearlyPricingClick };

const gtagTrackLeadGenFormComplete = () => {
  if (window.gtag) {
    window.gtag("event", "lead_gen_form_complete", {
      event_category: "Lead Generation",
      event_label: "Lead Gen Form Completed",
      value: 1,
    });
  }
};

export { gtagTrackLeadGenFormComplete };
