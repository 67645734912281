import React, { lazy, Suspense } from "react";
import Hero from "../components/bookDemo/Hero";

const FeatureCards = React.lazy(() => import("../components/bookDemo/FeatureCards"));
const ProblemSolution = React.lazy(() => import("../components/bookDemo/ProblemSolution"));
const TargetAudience = React.lazy(() => import("../components/bookDemo/TargetAudience"));
const HowItWorks = React.lazy(() => import("../components/bookDemo/HowItWorks"));
const FAQAccordion = React.lazy(() => import("../components/bookDemo/FAQCards"));
const BookDemoCTA = React.lazy(() => import("../components/bookDemo/BookDemoCTA"));
const TrustPilot = React.lazy(() => import("../components/bookDemo/TrustPilot"));

const BookDemo = () => {
  return (
    <Suspense fallback={<div className="loading-spinner">Loading...</div>}>
      <div>
        <Hero />
        <ProblemSolution />
        <FeatureCards />
        <TargetAudience />
        <HowItWorks />
        <FAQAccordion />
        <BookDemoCTA />
        <TrustPilot />
      </div>
    </Suspense>
  );
};

export default BookDemo;
