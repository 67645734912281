import React, { useState } from "react";
import HeroTitle from "./HeroTitle";
import CTAButton from "./CTAButton";
import backtestImage from "../images/trade_performance/CrudeConquerorBacktest.png";
import equityCurveImage from "../images/trade_performance/CrudeConquerorEquity.png";
import monteCarloImage from "../images/trade_performance/CrudeConquerorMC.png";
import strategyImage from "../images/trade_performance/demo_tradeorfade_compressed.gif";
import algoTrial from "../images/algo_trial_banner.png";
import "./StrategyDetails.css";
import Counter from "./Counter";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Image from "react-image-enlarger";
import { height, width } from "@mui/system";

const StrategyDetails = () => {
  const [isBacktestZoomed, setBacktestZoomed] = useState(false);
  const [isEquityCurveZoomed, setEquityCurveZoomed] = useState(false);
  const [isMonteCarloZoomed, setMonteCarloZoomed] = useState(false);
  const title = "CrudeConqueror";
  const subtitle =
    "A long and short intraday CL system that trades on the 40 minute chart.";
  return (
    <div>
      <div className="">
        <div className="hero-content text-left mb-[120px]">
          <h1
            className="flex-grow pb-[14px] ] mt-[20px] md:mt-4 mb-2 text-center font-bold tracking-tighter
                      bg-gradient-to-bl from-[deepskyblue] to-emerald-500
                      text-transparent bg-clip-text pr-2
                      md:mb-6 text-5xl md:text-8xl"
          >
            {title}
            <a />
          </h1>
          <a
            className="font-medium tracking-widest w-[360px] md:w-[800px]
                      text-white
                      text-sm lg:px-0 md:text-md lg:text-lg
                      inline-block"
          >
            <span>{subtitle}</span>
          </a>
          <div
            className="w-full blur-[200px] rounded-full h-72 absolute mt-[200px]
            bg-gradient-to-t from-black to-emerald-500/10 -z-20"
          ></div>
        </div>
        <div className="strategy-image-wrapper">
          <a
            href="https://buy.stripe.com/14kaIo6khbjo2Pu9B8"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <Image
              src={algoTrial}
              style={{
                cursor: "pointer",
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            /> */}
          </a>
        </div>
      </div>
      <div className="strategy-details-container">
        <div className="strategy-details-content">
          <div className="strategy-info">
            {/* Strategy price */}
            {/* <h1>$4,995</h1> */}

            {/* <a
              href="https://buy.stripe.com/cN27wcbEB9bgdu8fZE"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className="ui-btn-strat mx-start mt-8 mb-4" type="submit">
                <span className="w-full">Buy This Algo</span>
              </button>
            </a> */}
            <a
              href="https://buy.stripe.com/28o7wcbEBfzE9dS7tg"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button
                className="ui-btn-strat mx-start mb-[20px]"
                style={{ backgroundColor: "green" }}
                type="submit"
              >
                <span className="w-full">Get the ZTA Quant portfolio</span>
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="strategy-section">
        <div className="strategy-block">
          <h1>How It Works</h1>
          <p>
            CrudeConqueror takes advantage of the market volatility of Crude Oil
            contracts, holds for the bigger move and cuts losers fast.
          </p>
        </div>
        <div className="strategy-block">
          <h1>Instruments</h1>
          <p>CL, MCL Futures on a 30-minute timeframe</p>
        </div>
        <div className="strategy-block">
          <h1>Account Size</h1>
          <p>
            CrudeConqueror has shown stable performance in historical backtests,
            and we recommend a simulation account size of at least $100,000 to
            test this strategy.
          </p>
        </div>
      </div>

      <div>
        <h1 className="historical-title">Historical Performance</h1>
      </div>

      <div className="historical-container">
        <div className="historical-block">
          <h1>Backtest 2008-2023</h1>
          <Image
            src={backtestImage}
            zoomed={isBacktestZoomed}
            alt="Historical backtest trading results for the CrudeConqueror trading strategy, from 2008-2023"
            onClick={() => setBacktestZoomed(true)}
            onRequestClose={() => setBacktestZoomed(false)}
          />
        </div>
        <div className="historical-block">
          <h1>Equity Curve</h1>
          <Image
            src={equityCurveImage}
            zoomed={isEquityCurveZoomed}
            alt="Historical equity curve performance of the CrudeConqueror trading strategy"
            onClick={() => setEquityCurveZoomed(true)}
            onRequestClose={() => setEquityCurveZoomed(false)}
          />
        </div>
      </div>

      <div>
        <h1 className="historical-title">Performance Highlights</h1>
      </div>
      <div className="highlights-section">
        <div className="highlights-block">
          <Counter endValue={191835} showPrefix={true} />
          <p>Historical Net Profit</p>
        </div>
        <div className="highlights-block">
          <Counter endValue={14188} showPrefix={true} />
          <p>Maximum Drawdown</p>
        </div>
        <div className="highlights-block">
          <h1>53%</h1>
          <p>Win Rate</p>
        </div>
        <div className="highlights-block">
          <Counter endValue={13} showPrefix={false} />
          <p>Net Profit/DD Ratio</p>
        </div>
      </div>

      <div className="disclaimer-section">
        <h1>Disclaimer</h1>
        <p>
          The results shown for this strategy are hypothetical and based on
          simulated returns. They do not represent actual trading results.
          Trading involves risk, and you should only trade with capital you can
          afford to lose. The performance of this strategy in the past does not
          guarantee future results.
        </p>
      </div>
    </div>
  );
};

export default StrategyDetails;
