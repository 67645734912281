import React from "react";
import "./HeroTitle.css";
import PricingCards from "./PricingCards";
import imgTradeOrFade from "../images/trade_performance/TradeOrFade_NQ_Equity.webp";
import imgTrendHunter from "../images/trade_performance/trendhunter_equity.jpg";
import imgGoldDigger from "../images/trade_performance/GoldDigger_Equity.webp";
import imgCrudeConqueror from "../images/trade_performance/CrudeConquerorEquity.png";
import imgCL_Short from "../images/trade_performance/CL_Short_60Min_Equity.webp";
import imgCP_NQ from "../images/trade_performance/CP_NQ_30Min_Equity.webp";
import imgEquitySurfer from "../images/trade_performance/EquitySurfer_Equity.png";
import imgGCDaily from "../images/trade_performance/GC_ValueOpen_Equity.png";
import imgBlueFlame from "../images/trade_performance/BlueFlame_Equity.png";
import imgReversionRally from "../images/trade_performance/reversionrally_equity.jpg";
import imgVS from "../images/trade_performance/VS_NQ_30Min_Equity.png";
import imgWM from "../images/trade_performance/WM_NQ10Min_Equity.png";
import imgES60 from "../images/trade_performance/ES60MR_Equity.png";

import algoTrial from "../images/algo_trial_banner.png";

import StrategyItem from "../components/StrategyItem";
import { Fade } from "react-reveal";

const SectionTitleStrategies = React.memo(({ title, subtitle }) => {
  return (
    <div
      className="hero-content mx-auto pt-[56px] px-[10vw]
                    bg-gradient-to-b from-[#141721] via-black/60 to-[#141721] w-full mb-[56px]"
    >
      <div className="hero-content text-left mb-[120px]">
        <h1
          className="flex-grow pb-[14px] ]mt-20 md:mt-4 mb-2 text-center font-bold tracking-tighter
                      bg-gradient-to-r from-rose-200 via-sky-200 to-slate-200
                      text-transparent bg-clip-text pr-2
                      text-5xl md:mb-6 md:text-7xl lg:text-8xl"
        >
          Our Strategies
          <a />
        </h1>
        <a
          className="font-medium tracking-widest w-[360px] md:w-[800px]
                      text-emerald-300
                      text-sm lg:px-0 md:text-md lg:text-lg
                      inline-block"
        >
          <span>{subtitle}</span>
        </a>
        <div
          className="w-full blur-[160px] rounded-full h-72 absolute mt-[200px]
            bg-gradient-to-b from-blue-400/10 to-teal-500/20 z-10"
        ></div>
      </div>
      <div className="hero-content w-full px-[5vw] z-10">
        <Fade>
          <StrategyItem
            imageSrc={imgTradeOrFade}
            title="TradeOrFade"
            description="TradeOrFade is a momentum based intraday strategy on the 5 minute chart. It determines whether or not there is an opening gap on NQ/MNQ and takes advantage of statistically relevant information regarding gap ups and gap downs. It takes a starter position and pyramids into the position."
          />
          <StrategyItem
            imageSrc={imgGoldDigger}
            title="GoldDigger"
            description="GoldDigger is an intraday strategy on the 20 minute chart. Designed to take an initial amount of Gold futures(GC/MGC) contracts, and scales into each position. It uses a few custom indicators that will also be included with purchase."
          />
          <StrategyItem
            imageSrc={imgCrudeConqueror}
            title="CrudeConqueror"
            description="CrudeConqueror is an intraday Crude Oil (CL/MCL) system that trades on the 40 minute chart. It is a short only strategy and pyramids into trades based on current volatility."
          />
          <StrategyItem
            imageSrc={imgCL_Short}
            title="CL Short 30Min"
            description="CL Short 30Min is an intraday Crude Oil system that uses Woodies Pivots to identify sniper entries for downside breakouts."
          />
          <StrategyItem
            imageSrc={imgCP_NQ}
            title="CP NQ 30Min"
            description="CP NQ 30Min is an NQ system that goes both long and short. It uses Camarilla Pivots to identify trends, and tries to capture the move."
          />
          <StrategyItem
            imageSrc={imgEquitySurfer}
            title="EquitySurfer"
            description="EquitySurfer is an intraday ES system that provides great diversification across the major indexes."
          />
          <StrategyItem
            imageSrc={imgGCDaily}
            title="GC Daily"
            description="This is a Gold futures daily (swing trading) system. It holds a position for a maximum of 5 days, aiming to capture large moves in Gold futures."
          />
          <StrategyItem
            imageSrc={imgES60}
            title="ES60 Mean Reversion"
            description="ES60 Mean Reversion is a great team player, it works on the 60 minute chart and buys dips on ES futures, hoping for a reversion back to the mean."
          />
          <StrategyItem
            imageSrc={imgBlueFlame}
            title="BlueFlame"
            description="BlueFlame is a Natural Gas futures algorithm that takes trades between the hours of 8am-2:30pm CST."
          />
          <StrategyItem
            imageSrc={imgReversionRally}
            title="ReversionRally NQ 60Min"
            description="ReversionRally is a mean reversion, long only NQ system that aims to buy dips on the 60 minute chart. A fantastic diversifier in any trend portfolio."
          />
          <StrategyItem
            imageSrc={imgWM}
            title="WM NQ 10Min"
            description="WM NQ 10Min is an intraday NQ algorithm. It aims to identify strong trends, and joins the trend with precise entries."
          />
          <StrategyItem
            imageSrc={imgVS}
            title="VS NQ 30Min"
            description="VS NQ 30Min is an intraday NQ algorithm. It looks for strong breakouts in Nasdaq futures."
          />
          <div
            className="w-full blur-[160px] rounded-full h-72 absolute mt-[200px]
                bg-gradient-to-b from-blue-400/10 to-teal-500/20 -z-10"
          ></div>
        </Fade>
      </div>
    </div>
  );
});

export default SectionTitleStrategies;
