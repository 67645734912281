import React, { useEffect, useState } from "react";
import HeroTitle from "../components/HeroTitle";
import backtestImage from "../images/trade_performance/tradeorfade_backtest_summary.jpg";
import equityCurveImage from "../images/trade_performance/tradeorfade_equity.jpg";
import monteCarloImage1 from "../images/EquitySurferMC.png";
import portfolioCorrelation from "../images/portfolio_analyzer/quant_1_portfolio_correlation.png";
import live from "../images/portfolio_analyzer/live_1.png";
import "./QuantPortfolio.css";
import equitySurferMC from "../images/EquitySurferMC.png";
import blueFlameMC from "../images/BlueFlameMC.png";
import crudeConquerorMC from "../images/CrudeConquerorMC.png";
import nasdaqNavigatorMC from "../images/NasdaqNavigatorMC.png";
import goldDiggerMC from "../images/GoldDiggerMC.png";
import candleScalperMC from "../images/CandleScalperMC.png";
import equitySurferBT from "../images/trade_performance/EquitySurferBacktest.png";
import equitySurferEQ from "../images/trade_performance/EquitySurferEquity.png";

import nasdaqNavigatorBT from "../images/trade_performance/NasdaqNavigatorBacktest.png";
import nasdaqNavigatorEQ from "../images/trade_performance/NasdaqNavigatorEquity.png";

import goldDiggerBT from "../images/trade_performance/GoldDiggerBacktest.png";
import goldDiggerEQ from "../images/trade_performance/GoldDiggerEquity.png";

import candleScalperBT from "../images/trade_performance/CandleScalperBacktest.png";
import candleScalperEQ from "../images/trade_performance/CandleScalperEquity.png";

import crudeConquerorBT from "../images/trade_performance/CrudeConquerorBacktest.png";
import crudeConquerorEQ from "../images/trade_performance/CrudeConquerorEquity.png";

import blueFlameBT from "../images/trade_performance/BlueFlameBacktest.png";
import blueFlameEQ from "../images/trade_performance/BlueFlameEquity.png";

import returnsTable from "../images/trade_performance/zta_quant_portfolio_returns_table.png";
import returnsImage from "../images/tof_month_returns.jpg";
import Counter from "../components/Counter";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Image from "react-image-enlarger";
import MonthlyReturnsTable from "../components/MonthlyReturnsTable";
import gtagReportConversion from "../util/GoogleTagUtils";
import QuantFeatureCards from "../components/QuantFeatureCards";
import PortfolioCorrelationSection from "../components/QuantCorrelationSection";
import PerformanceHighlightsSection from "../components/PerformanceHighlightsSection";
import HistoricalPerformance from "../components/HistoricalPerformance";
import QuantDisclaimer from "../components/QuantDisclaimer";
import { Link } from "react-router-dom";

const QuantPortfolio = () => {
  useEffect(() => {}, []);

  const [isBacktestZoomed, setBacktestZoomed] = useState(false);
  const [isEquityCurveZoomed, setEquityCurveZoomed] = useState(false);
  const [isMonteCarloZoomed, setMonteCarloZoomed] = useState(false);
  const [isReturnsZoomed, setReturnsZoomed] = useState(false);

  const handleButtonClick = (paymentUrl, value) => {
    console.log(`handleButtonClick() ${paymentUrl}, ${value}`);

    if (window.gtag) {
      window.gtag("event", "lease_button_clicked", {
        event_category: "Checkout",
        event_label: "Lease for $997/year",
        value: value,
      });
    }

    gtagReportConversion(paymentUrl, value);
  };

  const title = "ZTA Quant Portfolio";
  const subtitle =
    "A diversified, low correlation portfolio that trades across timeframes and instruments. Contains 12 fully automated algos that trade ES, NQ, GC, CL, NG.";

  return (
    <div className="flex overflow-hidden flex-col justify-between items-center py-20 pl-1 pr-1 md:pr-2 md:pl-2 max-md:px-5 max-md:py-24 bg-[#141721]">
      <div className="flex flex-col items-center self-center w-auto max-w-full">
        {/* Title Section */}
        <div className="flex flex-col md:flex-row flex-wrap max-w-full text-7xl lg:text-8xl gap-2 md:gap-8 font-semibold text-center">
          <div className="bg-gradient-to-r from-[#569DB7] to-[#C2D1FB] bg-clip-text text-transparent">
            <h1 className="flex-auto tracking-tighter max-md:max-w-full">
              ZTA Quant
            </h1>
          </div>
          <h1 className="flex-auto tracking-wider text-[#DD52A7] max-md:max-w-full">
            Portfolio
          </h1>
        </div>

        {/* Description & Primary CTA */}
        <div className="flex flex-wrap justify-center md:justify-normal gap-10 mt-8 mb-8 max-w-full rounded-[999px] w-[712px]">
          <p className="flex-auto text-center px-4 md:px-0 text-base font-medium tracking-tight leading-6 text-zinc-300 w-[502px]">
            A diversified, low correlation portfolio that trades across
            timeframes and instruments. Contains 12 fully automated algos that
            trade ES, NQ, GC, CL, NG.
          </p>
          <div className="hero-btn-container">
            <Link to="/strategies">
              <button className="hero-btn">
                <p data-text="View Algos" data-title="View Algos">
                  View Algos
                </p>
              </button>
            </Link>
          </div>
        </div>

        {/* Main Image Section */}
        <div className="w-full lg:w-[1259px] h-[400px] md:h-[640px] lg:h-[843px] relative">
          <div className="w-full md:w-[1081px] h-[158px] md:left-[86px] top-[200px] md:top-[645px] opacity-60 absolute bg-gradient-to-r from-[#8a3c73] to-[#1c3a5a] blur-[60px] md:blur-[94px]" />
          <div className="w-full lg:w-[932px] h-[128px] md:left-[173px] top-0 absolute bg-[#1a233e] blur-[94px]" />
          <div className="left-0 -top-[12px] md:top-[18px] absolute w-full lg:w-[1259px] h-[350px] md:h-[600px] lg:h-[784px] overflow-hidden drop-shadow-2xl">
            <div className="bg-hero bg-no-repeat ms-0 w-full h-[350px] md:h-[100%] md:w-[100%]" />
          </div>
        </div>

        {/* Pricing & Lease Button Section */}
        <div className="md:h-[499.40px] w-full lg:w-[1312px] -mt-12 md:mt-6 flex-col justify-start items-start gap-[20px] md:gap-[87px] inline-flex">
          <div className="flex md:flex-wrap gap-12 max-w-full lg:w-[864px]">
            <h2 className="flex-auto text-4xl md:text-7xl lg:text-8xl font-bold tracking-tighter leading-none text-center text-slate-300 max-md:max-w-full">
              $1,997/year
            </h2>
            <div className="hero-btn-container">
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                onClick={() =>
                  handleButtonClick(
                    "https://buy.stripe.com/28o7wcbEBfzE9dS7tg",
                    1,
                    997.0
                  )
                }
              >
                <button className="hero-btn">
                  <p
                    data-text="Lease for $997/year"
                    data-title="Lease for $997/year"
                  >
                    Lease for $1,997/year
                  </p>
                </button>
              </a>
            </div>
          </div>
          <QuantFeatureCards />
        </div>

        <PortfolioCorrelationSection />
        <PerformanceHighlightsSection />

        {/* Returns Table Section */}
        <div className="flex justify-center mt-8">
          <img
            src={returnsTable}
            alt="portfolio-returns"
            className="w-full max-w-[1259px] h-auto rounded-lg shadow-lg"
          />
        </div>
        <QuantDisclaimer />
      </div>
    </div>
  );
};

export default QuantPortfolio;
