import React from 'react';
import largeImage from '../images/ipad_mockup.webp'
import largeImage2 from '../images/desktop_mockup_2.webp'
import './HeroImage.css'


const HeroImage = () => {
    return (
      <div className="image-section">
        <img src={largeImage} alt="Large Image" loading="lazy"/>
        <img src={largeImage2} alt="Large Image2" loading="lazy"/>
      </div>
    );
  };

  export default HeroImage;