import React from "react";
import "./StrategyItem.css";
import { Link } from "react-router-dom";

const StrategyItem = ({ imageSrc, title, description }) => {
  // Encode the title to format the URL correctly
  const formattedTitle = encodeURIComponent(title);

  return (
    <div className="strategy-info-block">
      <img
        src={imageSrc}
        alt="Image"
        className="strategy-info-block__img"
        loading="lazy"
      />
      <div className="strategy-info-block__content">
        <h2 className="strategy-info-block__title">{title}</h2>
        <p className="strategy-info-block__description">{description}</p>
        <div className="strategy-btn-container">
          <Link to={`/strategies/${formattedTitle}`}>
            <button className="hero-btn">
              <p data-text="View Performance" data-title="View Performance">
                View Performance
              </p>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StrategyItem;
