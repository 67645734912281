import "./HeroTitle.css";
import { Link } from "react-router-dom";
import "./CTAButton.css";
import ninjaTraderLogo from "../images/ninjatrader-logo.png";
import desktopMock from "../images/desktop_mockup_2.webp";

const SectionIntegration = () => {
  const subtitle = "Easily set up and start trading within minutes.";
  return (
    <div className="mx-auto mt-[-40px] md:pt-[20px] pb-[20px]">
      <div className="hero-content">
        <h1
          className="flex-grow text-center font-bold tracking-wide md:tracking-tighter pb-2
                      bg-gradient-to-r from-slate-100 via-sky-200 to-slate-700
                      text-transparent bg-clip-text
                      text-5xl mb-4 md:mb-6 md:text-7xl pt-20 lg:text-7xl"
        >
          Seamless{" "}
          <a
            className="bg-gradient-to-r from-red-400 to-red-500/80
                                         text-transparent bg-clip-text"
          >
            {" "}
            Integration
          </a>
        </h1>
        <a
          className="font-medium tracking-widest
                      text-[#f45925]/80 text-center pb-2 md:pb-0
                      md:tracking-tight w-[80vw] md:w-[500px] text-md lg:px-0 md:text-lg lg:text-lg"
        >
          <span>{subtitle}</span>
        </a>
        <button
          className="bg-gradient-to-b from-[#141721] to-slate-900
                  rounded-2xl mt-5 md:mt-1 drop-shadow-2xl mx-auto w-[60vw] md:w-auto"
        >
          <Link to="/platform">
            <img src={ninjaTraderLogo} alt="NinjaTrader Logo" />
          </Link>
        </button>
        <div className="py-12 md:py-16 lg:py-20">
          <div
            className="grid px-[5vw] md:px-0 grid-cols-1 md:grid-cols-2
                        gap-12 justify-items-start"
          >
            <img
              src={desktopMock}
              alt="desktopMockup"
              className="object-cover w-[90vw] md:w-[48] lg:w-[24vw]"
            />
            <div className="w-full max-w-xl">
              <a
                className="font-light tracking-widest text-left leading-relaxed
                                        text-white
                                        text-sm md:text-lg lg:text-xl"
              >
                <p className="mb-4">
                  Experience seamless integration with NinjaTrader, one of the
                  industry's leading futures trading platforms. Our fully
                  automated systems are specifically designed to work flawlessly
                  with NinjaTrader, empowering you to execute trades with
                  precision and efficiency.
                </p>
                <p className="mb-4">
                  Benefit from a comprehensive suite of powerful tools, advanced
                  charting capabilities, and a user-friendly interface that
                  enables you to harness the full potential of our trading
                  algorithms.
                </p>
                <p>
                  Unlock your trading potential with NinjaTrader and take your
                  futures trading to new heights.
                </p>
              </a>
            </div>
          </div>
        </div>
        <div
          className="mt-[5%vh] w-full blur-[160px] rounded-full h-72 absolute
                             bg-gradient-to-r from-[#2C1036]/40 to-slate-200/10 -z-20"
        ></div>
      </div>
    </div>
  );
};

export default SectionIntegration;
