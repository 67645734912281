import React, { useState } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { gtagTrackYearlyPricingClick } from "../util/GoogleTagUtils";

const useStyles = makeStyles({
  pricingContainer: {
    display: "flex",
    gap: 16,
    margin: "auto",
    justifyContent: "left",
    maxWidth: "1200px",
    marginBottom: "10%",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  pricingCard: {
    flex: 1,
    flexDirection: "column",
    fontFamily: "Montserrat",
    position: "relative",
    fontWeight: "800",
    margin: "14px",
    opacity: 0.8,
    boxShadow: "10px 5px 20px black",
    backgroundColor: "#141721",
    color: "black",
    width: "100%",
    maxWidth: "90vw",
    padding: "20px",
    borderRadius: "10px",
    minHeight: "650px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 768px)": {
      minHeight: "auto",
      paddingBottom: "30px",
    },
  },
  priceText: {
    fontWeight: "900",
    color: "#44d8a4",
    fontFamily: "Montserrat",
    marginTop: "14px",
    marginBottom: "14px",
    fontSize: "2rem",
    "@media (max-width: 768px)": {
      fontSize: "1.8rem",
    },
  },
  features: {
    fontSize: "1rem",
    color: "lightgray",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: 2.0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    "@media (max-width: 768px)": {
      fontSize: "0.9rem",
      lineHeight: 1.8,
    },
  },
  pricingCTAContainer: {
    justifyContent: "center",
    display: "flex",
    marginTop: "auto",
    paddingBottom: "20px",
  },
});

const PricingCards = () => {
  const classes = useStyles();

  return (
    <div id="pricing-section">
      <div className={classes.pricingContainer}>
        <Card className={classes.pricingCard}>
          <CardContent
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Typography
              variant="h5"
              component="h2"
              className="text-3xl font-extrabold text-[#CCFFFF]"
            >
              ZTA Quant Portfolio
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              className={classes.priceText}
            >
              $1,997/year
            </Typography>
            <ul className={classes.features}>
              <li>✔ No setup fee</li>
              <li>
                ✔ 30-day <strong>money back guarantee</strong>
              </li>
              <li>✔ 12 Algos trading ES, NQ, GC, CL, NG</li>
              <li>
                ✔{" "}
                <Link
                  to="/portfolio_analyzer"
                  className="underline text-[#CCFFFF]"
                >
                  <strong>Portfolio Analyzer</strong>
                </Link>{" "}
                lifetime license
              </li>
              <li>✔ Strategy templates & settings</li>
              <li>✔ Discord community support</li>
              <li>✔ 24/7 Email support</li>
              <li>✔ Chat Support</li>
              <li>✔ System upgrades & improvements</li>
              <li>✔ Optimization guide</li>
              <li>✔ Unlimited access to future algos</li>
            </ul>
            <div className={classes.pricingCTAContainer}>
              <Link
                to="/portfolios/zta_quant_portfolio"
                onClick={() => gtagTrackYearlyPricingClick()}
                className="justify-center flex p-1"
              >
                <div className="buttons">
                  <button className="btn">
                    <span></span>
                    <p data-text="Let's Go!" data-title="Get Started!"></p>
                  </button>
                </div>
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default PricingCards;
