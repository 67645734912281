import React, { useState, useEffect } from "react";
import "./HeroTitle.css";
import { Link } from "react-router-dom";
import YoutubeEmbed from "./YoutubeEmbed";

const SectionTitleYoutube = () => {
  const subtitle =
    "Subscribe to our Youtube channel for exclusive content, insights and updates.";
  return (
    <div className="inline-block mx-auto pt-[20px] pb-[20px] w-[99vw] md:w-[1200px] items-center">
      <div className="inline-block text-center pb-4">
        <div
          className="grid grid-cols-1 md:grid-col-2 w-[99vw] md:w-auto
                        bg-gradient-to-r text-transparent bg-clip-text
                        text-end from-pink-300/90 via-sky-400/80 to-pink-200"
        >
          <h1
            className="text-center font-bold md:w-auto
                    tracking-tighter text-5xl mb-6 md:mb-6 md:text-5xl lg:text-7xl"
          >
            Growing Youtube Community
          </h1>
          <p
            className="md:px-[10%] text-center leading-tight mx-auto
                    text-lg md:text-lg tracking-widest text-slate-300
                    font-medium w-[80vw] md:w-auto"
          >
            {subtitle}
          </p>
        </div>
      </div>
      <div className="w-[90%] blur-[160px] rounded-full h-52 absolute bg-gradient-to-t from-indigo-500/60 to-black z-20"></div>
      <YoutubeEmbed
        youtubeUrl="https://www.youtube.com/embed/vK-t2dG0oCg?si=fa0FjQcJOjJR2Za2"
        width="65%"
        height="30%"
      />
      <div className="hero-btn-container">
        <Link to="https://www.youtube.com/@ziontradingalgos?sub_confirmation=1">
          <button className="hero-btn">
            <p data-text="SUBSCRIBE" data-title="SUBSCRIBE">
              SUBSCRIBE
            </p>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default SectionTitleYoutube;
