import React from "react";
import { Link } from "react-router-dom";
import { openCalendlyPopup } from "../../util/calendly";
import "./Hero.css";
import arrowRight from "../../images/arrow-right.svg";

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="hero-section-content">
        <Link to="/portfolio_analyzer" className="portfolio-analyzer-link">
          New | Portfolio Analyzer{" "}
          <img src={arrowRight} alt="Arrow" className="arrow-icon" />
        </Link>
        <h1>
          Automate Your Trading. <br /> Grow Your Wealth.{" "}
          <span className="hero-section-highlight">Stress-Free</span>
        </h1>
        <p>
          Discover how our pre-built trading strategies harness algorithmic
          trading to free up your time and help you pursue wealth-building
          opportunities—without the daily grind of manual trading.
        </p>
        <a
          href="#"
          onClick={openCalendlyPopup}
          className="cta-button hero-section-button"
        >
          Book Your Free Demo
        </a>
      </div>
    </section>
  );
};

export default Hero;
