export const openCalendlyPopup = (e) => {
  e.preventDefault();
  if (window.innerWidth < 768) {
    window.open("https://calendly.com/jjbyfield/30min", "_blank");
  } else if (window.Calendly) {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/jjbyfield/30min?primary_color=7253a3",
    });
  }
};
