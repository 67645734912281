// DemoBookingThankYou.js
import React from "react";
import "../components/HeroTitle.css";

const DemoBookingThankYou = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <div className="hero-h1-container">
          <h1
            className="md:mt-2 mb-1 pr-2 pb-[20px] font-bold tracking-tighter
                      bg-gradient-to-bl from-blue-300 to-white
                      text-transparent bg-clip-text text-7xl
                      md:text-8xl md:mb-6"
          >
            Thank You
          </h1>
        </div>
        <h2 className="hero-h2">
          Thank you for booking your demo call. Please check your email for a
          confirmation. If for any reason you're unable to make your scheduled
          demo, please do us the courtesy of rescheduling via the confirmation
          email.
        </h2>
      </div>
    </div>
  );
};

export default DemoBookingThankYou;
