import React, { useState } from "react";
import "./ContactPopup.css";
import { gtagTrackLeadGenFormComplete } from "../util/GoogleTagUtils";
import strategy_eq from "../images/trade_performance/nasdaqhitter_eq.png";

function ContactPopup({ buttonText, onClose }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [consent, setConsent] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!consent) {
      alert("Please check the box to receive emails, insights, and research.");
      return;
    }
    try {
      const response = await fetch(
        "https://ztabackend-8c250e04a1fb.herokuapp.com/contactSubscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: name, email: email }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Response from server:", result.message);

      // Call GA event for form completion
      gtagTrackLeadGenFormComplete();

      setFormSubmitted(true);
      setTimeout(onClose, 5000);
    } catch (error) {
      console.error("Error submitting contact form:", error);
      alert("Failed to send the email, please try again!");
    }
  };

  return (
    <div className="popup-backdrop">
      <div className="popup">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h1 className="popup-title">
          {formSubmitted ? "Thank You!" : "Want A Free Algo?"}
        </h1>
        {!formSubmitted && (
          <>
            <div className="image-container">
              <img
                src={strategy_eq}
                alt="Strategy"
                className="strategy-image"
              />
            </div>
            <p className="centered-text">
              Join our community of algorithmic traders and receive a free
              NinjaTrader trading bot via email.
            </p>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                className="input-field"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="email"
                className="input-field"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="consent"
                  checked={consent}
                  onChange={(e) => setConsent(e.target.checked)}
                  required
                />
                <label htmlFor="consent">
                  I agree to receive emails, insights, and research.
                </label>
              </div>
              <button type="submit" className="submit-button">
                {buttonText}
              </button>
            </form>
          </>
        )}
        {formSubmitted && (
          <p className="centered-text">
            You should receive an email soon containing a link to download and
            set up your free NinjaTrader bot.
          </p>
        )}
      </div>
    </div>
  );
}

export default ContactPopup;
